import React, { ReactElement } from "react";
import MainBanner from "@components/workerscompensationrates/mainBanner";
import WorkersCompensationRatesWrapper from "@components/layouts/workersCompensationRatesWrapper";
import Header from "@components/shared/header";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import styles from "./style.module.scss";
import colors from "@styles/variables/workersCompensationRatesVariables.module.scss";
import WorkersCompensationRatesLogo from "@components/workerscompensationrates/logo";
const WhyUs = dynamic(
    () => import("@components/workerscompensationrates/whyUs"),
);
const OffersList = dynamic(() => import("@components/shared/offersList"));

export default function WorkersCompensationRatesHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <WorkersCompensationRatesWrapper>
            <div className={styles.topWrapper}>
                <Header
                    textColor={colors.headerPhoneColor}
                    backGroundColor="transparent"
                    slogan="the best workers compensation rates"
                >
                    <WorkersCompensationRatesLogo />
                </Header>
                <MainBanner />
            </div>
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhyUs />
        </WorkersCompensationRatesWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
