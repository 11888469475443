import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/workersCompensationRatesVariables.module.scss";
import Image from "next/image";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const router = useRouter();
    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={`${styles["main-banner"]}`}>
                    <div
                        className={`flex justify-center items-center ${styles["wrapper"]}`}
                    >
                        <div className={styles["sideForm"]}>
                            <Form
                                classes={{
                                    formClassName: styles["form"],
                                    stepTitleClassName: styles["step-title"],
                                    stepClassName: styles["step-block"],
                                    stepDescriptionClassName:
                                        styles["step-description"],
                                    formButtonClassName: styles["form-button"],
                                    formFieldClassName: styles["form-field"],
                                    formStepInnerClassName:
                                        styles["steps-inner"],
                                    fieldsClassName: {
                                        radio: styles["radio-field"],
                                        checkbox: styles["checkbox-field"],
                                    },
                                    fieldsWrapperClassName:
                                        styles["fields-wrapper"],
                                    tcpaClassName: styles["tcpa"],
                                    labelAsTitleClassName:
                                        styles["label-as-step-title"],
                                    sellingPointClassName:
                                        styles["selling-point"],
                                }}
                                backButton={{
                                    class: styles["back-button"],
                                    label: "Back",
                                }}
                                colors={{
                                    primaryColor: colors.primaryColor,
                                    progressBar: colors.progressColor,
                                }}
                                showProgress={true}
                                preventWindowClose
                            />
                        </div>
                        <div className={styles["sideImage"]}>
                            <Image
                                src="/workerscompensationrates/assets/images/sideimageOpt.jpg"
                                alt="workers-compensation-insurance-rates"
                                layout="responsive"
                                priority
                                width={649}
                                height={667}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
